/* ==========================================================================
   #LEAD
   ========================================================================== */


.lead {
    @include font-size(20px, 24px);
    @include font-proxima-semi-bold;

}

.lead--non-bold {
    @include font-proxima-regular;
    margin-top: 20px;
}