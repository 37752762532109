/*------------------------------------*\
    #ELEMENTS
\*------------------------------------*/

.color-white {
    color: white;
}

.color-pink {
    color: $color-pink;
}

.color-mint {
    color: $color-mint;
}