/*------------------------------------*\
    #PAGE
\*------------------------------------*/

html {

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &:not(.page--home) {
        padding-top: $page-header-desktop;
    }
}


body {
    color: #000;
    @include font-size(16px, 20px);
    @include font-proxima-regular;

}

