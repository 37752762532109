/*------------------------------------*\
    #HELPERS
\*------------------------------------*/


.hovered {
    cursor: pointer;
}

.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.text--centered {
    text-align: center;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}

.caps {
    text-transform: uppercase;
}

.is-hidden {
    display: none;
}

.float--right {
    float: right;
}

.float--left {
    float: left;
}

// RESPONSIVE IFRAMES
iframe, object, embed {
    max-width: 100%;
}


.clickable {
    @include clearfix;
    &:hover {
        h2 {
            color: $color-brand;
        }

        .article__image-small {

        }
    }


}

a.go {
   text-decoration: double;
    &:after {
        content: ' >';
    }
}


.more {
    &:after {
        content: ' >';
        color: $color-vig-orange-lighten;
    }
}

/*------------------------------------*\
    #COLORS
\*------------------------------------*/

.color-blue {
    color: $color-btn-primary;
}

.color-grey {
    color: $color-headings;
}

.color-grey-light {
    color: $color-grey-light;
}


.color-vig-mint {
    color: $color-vig-mint;
    .lighten {
        color: $color-vig-mint-lighten;
    }
}

.color-vig-blue {
    color: $color-vig-blue;
    .lighten {
        color: $color-vig-blue-lighten;
    }
}

.color-vig-green {
    color: $color-vig-green;
    .lighten {
        color: $color-vig-green-lighten;
    }
}

.color-vig-orange {
    color: $color-vig-orange;
    .lighten {
        color: $color-vig-orange-lighten;
    }
}


.color-vig-purple {
    color: $color-vig-purple;
    .lighten {
        color: $color-vig-purple-lighten;
    }
}


.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}