/* ==========================================================================
   #MASTHEAD
   ========================================================================== */


.masthead {
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    background-color: $color-grey;
    height: 80vh;
    max-height: 100vw;
    position: relative;
}

.masthead__icon {
    width: 60px;
    height: 40px;
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(../img/icon-arrow-down-homepage.svg);
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    animation: bounce 1s infinite alternate;
}


@keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-15px);
    }
}
@-webkit-keyframes bounce {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-15px);
    }
}