/* ==========================================================================
   #ICONS
   ========================================================================== */

.icon {
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    background-position: center;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    text-indent: -999em;
}


.icon--video {
    background-image: url(../img/icon-video.svg);
}


.icon--facebook {
    background-color: #37517D;
    background-image: url(../img/icon-facebook.svg);
    @include attention() {
        background-color: darken(#37517D, 5%);
    }
}

.icon--linkedin {
    background-image: url(../img/icon-linkedin.svg);
    background-size: 12px!important;
}

.icon--twitter {
    background-color: #13A3F3;
    background-image: url(../img/icon-twitter.svg);
    @include attention() {
        background-color: darken(#13A3F3, 5%);
    }
}

.icon--mail {
    background-color: #00ADCA;
    background-image: url(../img/icon-mail.svg);
    @include attention() {
        background-color: darken(#00ADCA, 5%);
    }
}

.icon--social {
    background-size: 14px;
}

.icon--video-black {
    background-image: url(../img/icon-video-black.svg);
}

.icon--book {
    background-image: url(../img/icon-book.svg);
}

.icon--rounded {
    background-color: $color-grey-light;
    border-radius: 100%;
    background-size: 13px;
    @include attention() {
        background-color: #666;
    }
}