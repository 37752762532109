/*------------------------------------*\
    #BLOCKQUOTE
\*------------------------------------*/

blockquote {
    border-left: 1px solid ;
    margin: 2.5em 10px 2em 30px;
    padding: 0 20px;
    @include font-size(30px, 34px);
    font-family: Georgia, Times, 'Times New Roman', serif;
    @include mq($from: tablet) {
        margin: 2.5em 10px 2em 100px;
    }
}

blockquote:before {

    @include font-size(15px, 20px);
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p {
    display: inline;
}