/* ==========================================================================
   #ARTICLE
   ========================================================================== */

.article__description {
    border-left: solid 2px;
    padding-left: 20px;
    @include font-size(13px, 18px);
    @include mq($until: tablet) {
        margin-bottom: 40px;
    }

}

.article__content {

    @include mq($from: desktop) {
        margin-top: -60px;
        position: relative;
    }
}

.article__introduction {
    padding-right: 20px;

    @include mq($from: desktop) {
        padding-right: 65px;

    }

    .color-vig-mint & {
        color: $color-vig-mint-lighten;
    }

    .color-vig-blue & {
        color: $color-vig-blue-lighten;
    }

    .color-vig-green & {
        color: $color-vig-green-lighten;
    }

    .color-vig-orange & {
        color: $color-vig-orange-lighten;
    }

    .color-vig-purple & {
        color: $color-vig-purple-lighten;
    }

}

.article__image {
    margin-top: $base-spacing-unit*2;
    margin-bottom: $base-spacing-unit*2;
}

.article__image-title--black{
    @include mq($until: desktop) {
        color: #000000!important;
    }
}

.article__image-title {
    text-transform: uppercase;
    color: #000;
    @include font-proxima-semi-bold;
    h1 {
        @include font-size(38px, 40px);
    }
    text-align: left;
    padding-left: 20px;
    padding-top: 40px;
    padding-bottom: 10px;

    @include mq($from: tablet) {
        padding-left: 40px;
    }

    @include mq($from: desktop) {
        position: absolute;
        right: 100px;
        z-index: 1;
        top: 200px;
        width: 100%;
        max-width: 400px;
        h1 {
            @include font-size(38px, 40px);
        }
        padding: 20px 0;
        text-align: right;
        color: #fff;
    }
    @include mq($from: 1400px) {
        h1{
            @include font-size(48px, 50px);
        }
        right: 220px;
    }

    @include mq($until: desktop) {
        color: #000 !important;
    }

}

.article__title-left {
    padding-left: $half-spacing-unit;
    @include mq($from: phone, $until: desktop) {
        padding-left: $base-spacing-unit;
    }

    @include mq($from: desktop) {
        left: 80px;
        text-align: left;
        top: 40%;
        transform: translateY(-50%);
        padding-left: 0;
    }

    @include mq($from: 1400px) {
        left: 220px;
    }
}
.article__title-right {
    padding-left: $half-spacing-unit;
    @include mq($from: phone, $until: desktop) {
        padding-left: $base-spacing-unit;
    }

    @include mq($from: desktop) {
        right: 80px;
        text-align: right;
        top: 40%;
        transform: translateY(-50%);
        padding-left: 0;
    }

    @include mq($from: 1400px) {
        right: 220px;
    }
}


.article__image-holder {
    text-align: center;
    @include mq($from: desktop) {
        background-color: black;
    }
}

.article__image-hero {
    max-width: 1600px;
    position: relative;
    margin: 0 auto;

    .icon--video {
        position: absolute;
        bottom: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 100px;
        margin: -50px auto 0 auto;
        color: #white;

        @include mq($from: tablet) {
            bottom: 120%;

        }
        @include mq($from: desktop) {
            bottom: -15%;
            width: 50px;
            height: 50px;
            margin: 0px;

        }
    }
    .icon--video-text {
        display: none;
        @include mq($from: desktop){
            padding-top: 10px;
            color: white;
            text-transform: none;
            display: block;
            position: absolute;
        }
        @include mq($from: 1400px){
            padding-top: 23px;
        }
    }
    .icon--video-text-left{
        left: 65px;
    }
    .icon--video-text-right{
        right: 65px;
    }


    .icon--video-left {
        @include mq($from: desktop) {
            left: auto;
            transform: translate(0%, 0%);
            left: 0px;

        }

    }
    .icon--video-right {
        @include mq($from: desktop) {
            left: auto;
            transform: translate(0%, 0%);
            right: 0px;

        }


    }
}
.article__author {
    @include font-size(13px, 15px);
    display: block;
    margin-bottom: 3px;
}

/**
    ARTICLE LIST
 */

.article__list {

}

.article__title-small {
    @include font-proxima-semi-bold;
    @include font-size(19px, 19px);
    text-transform: uppercase;
    color: #000;
    transition: $global-transition;
    @include attention() {
        color: $color-brand;
    }

}

.article__image-container {
    position: relative;
    max-width: 220px;
    float: left;

    @include mq($from: phone-small, $until: phone) {
        max-width: 100%;
        margin-bottom: $base-spacing-unit;
    }

    @include mq($from: phone, $until: tablet) {
        margin-right: 12px;
    }

    @include mq($from: tablet, $until: 1200px) {
        max-width: 100%;
        margin-bottom: $base-spacing-unit;
    }

    @include mq($from: 1200px) {
        max-width: 180px;
        width: 100%;
        margin-right: 12px;
    }

    &::after {
        display: block;
        position: absolute;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.7) 100%);
        margin-top: -40px;
        height: 40px;
        width: 100%;
        content: '';

    }

}

.article__image-container--large {
    width: 100%;
    display: block;
    max-width: 100%;
    margin-bottom: $base-spacing-unit;
    .icon--full {
        position: absolute;
        width: 100%;
        display: block;
        top: 50%;
        height: 60px;
        margin-top: -30px;

        @include mq($from: tablet) {
            height: 130px;
            margin-top: -65px;
        }
    }
}

.article__image-small {
    max-width: 220px;
    transition: $global-transition;

    @include mq($from: phone-small, $until: phone) {
        max-width: 100%;
    }

    @include mq($from: tablet, $until: 1200px) {
        max-width: 100%;

    }

    @include mq($from: 1200px) {
        max-width: 180px;
        width: 100%;
    }

}

.article__sublabel {
    position: absolute;
    bottom: 6px;
    left: 8px;
    color: #fff;
    @include font-size(11px);
    margin-bottom: 0;
    z-index: 1;
    text-transform: uppercase;
    letter-spacing: -0;
}

.article__video {
    width: 45px;
    height: 45px;
    background: $color-vig-orange-lighten;
    border-radius: 90px 0 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    .icon {
        margin-left: 15px;
        margin-top: 14px;
    }
}

.article__label {
    @include font-size(11px, 14px);
    display: block;
    text-transform: uppercase;
}

.sharing {
    position: absolute;
    top: 80px;
    right: 0;
    z-index: 0;
    li {
        margin-bottom: 10px;
    }
}