/*------------------------------------*\
    #TIME
\*------------------------------------*/

.date {
    @include font-size(11px, 14px);
    text-transform: uppercase;
    color: $color-date-default;
}

.date--label {
    display: block;
    margin-bottom: $half-spacing-unit;
}