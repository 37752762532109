/* ==========================================================================
   #PAGINATION
   ========================================================================== */


.pagination {
    text-align: center;
    margin-bottom: $base-spacing-unit*3;
}


.pagination__link {
    color: $color-mint;
    text-transform: uppercase;
    @include font-size(13px);
    @include attention() {
        color: darken($color-mint, 10%);
    }
}


.pagination__separator {
    margin-left: 12px;
    margin-right: 12px;
    color: $color-mint;
}