/* ==========================================================================
   #SEARCH
   ========================================================================== */


.form-group {
    max-width: 600px;
    width: 100%;
    position: relative;
    float: left;
}

.seachform--large {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;

}


.seachform__label {
    color: #fff;
    float: left;
    margin-right: 20px;
    margin-top: 5px;
    @include font-size(16px, 30px);
}


.seachform__input {
    background: #FFFFFF;
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.50);
    border: none;
    padding: 10px 7px 8px 7px;
    @include font-size(12px, 16px);
    padding-right: 30px;
    width: 100%;
    transition: $global-transition;
    &:focus {
        background-color: lighten($color-brand, 50%);
    }

    &::placeholder {
        color: $color-brand;
        text-transform: uppercase;
    }

    .form-group & {
        box-shadow: none;
        border: solid 1px #A0A0A0;
        padding: 12px;
    }
    @include mq($until: 1024px) {
        border: solid 1px $color-ui;
    }
}



.seachform__submit {
    position: absolute;
    top: 22px;
    right: 16px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    background-image: url('/nvvr/img/icon-search.svg');
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9999em;

    @include mq($from: tablet) {
        top: 2px;
        right: 0;
    }


    &:hover {
        cursor: pointer;
    }

    .form-group & {
        background-image: url('/nvvr/img/icon-search-red.svg');
        top: 5px;
    }
}




