/* ==========================================================================
   #PAGE-FOOT
   ========================================================================== */

.page-foot .o-wrapper {
    border-top: solid 2px $color-ui;
    margin-top: $base-spacing-unit*3;
    padding-top: 10px;
    padding-bottom: 10px;
}


.footer-logo {
    width: 164px;
    height: 58px;
    display: block;
    background-image: url('../img/vig-logo.svg');
    margin-bottom: 20px;
    @include mq($from: tablet){
        float: left;
    }
}

.disclaimer {
    color: $color-grey;
    @include font-size(15px, 58px);
    @include mq($from: tablet){
        float: right;
    }
}

//MARKS EDITS

.footer-contact{
    @include font-size(14px,20px);
    color: #9B9B9B;
    display: block;
    @include mq($from:tablet){
        float: left;
        width: 19%;
        margin-top: 15px;
        margin-left:15px;
    }
}

.footer-text {

    @include font-size(14px, 20px);
    color: #9B9B9B;
    display: block;
    @include mq($from:tablet){
        float: left;
        width:33%;
        margin: 15px 38px;
    }
}