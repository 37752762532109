/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a {
    text-decoration: none;
    color: $color-brand;
    transition: $global-transition;
    @include attention(){
        color: darken($color-brand, 10%);
    }
}
