/* ==========================================================================
   #NOTES
   ========================================================================== */


.notes {
    margin-top: $base-spacing-unit*3;
    border-top: solid 1px;
    border-bottom: solid 1px;
    padding-top: $base-spacing-unit;
    padding-bottom: $base-spacing-unit;
    @include font-size(16px, 19px);

    h5 {
        @include font-size(16px, 19px);
    }


    &.color-vig-mint {
        p {
            color: $color-vig-mint;
        }
    }
    &.color-vig-blue {
        p {
            color: $color-vig-blue;
        }
    }
    &.color-vig-green {
        p {
            color: $color-vig-green;
        }
    }
    &.color-vig-orange {
        p {
            color: $color-vig-orange;
        }
    }

}
