/* ==========================================================================
   #BLOCK
   ========================================================================== */

.block {
    &:hover {
        .block__title {
            color: $color-vig-orange-lighten;

        }
    }
}


.block__label {
    color: #9B9999;
    text-transform: uppercase;
    @include font-size(14px);
    margin-bottom: 6px;
}

.block .block__title {
    color: #444;
    margin-top: 12px;
    text-transform: uppercase;
    @include font-size(22px, 24px);
    transition: $global-transition;
    &:after {
        content: '>';
        @include font-size(18px, 20px);
        color: $color-vig-orange-lighten;
        vertical-align: top;
        @include font-proxima-regular;
        margin-left: 6px;
    }
}

