/*------------------------------------*\
	NAV
\*------------------------------------*/

.menu-helper {
    position: fixed;
    top: 22px;
    right: 70px;
    text-transform: uppercase;
    color: $color-grey-light;
    z-index: 100;

    .page--home & {
        color: #fff;
        top: 18px;
    }
}

.hamburger {
    position: absolute;
    top: 6px;
    right: 6px;

    @include mq($until: tablet) {
        display: block;
    }

    .page--home & {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        .hamburger-inner {
            background-color: #fff;
            &:before, &:after {
                background-color: #fff;
            }
        }

        &.is-active .hamburger-inner {
            background-color: $color-vig-orange!important;
            &:before, &:after {
                background-color: $color-vig-orange!important;
            }
        }
    }



}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: 280px;
    height: 100vh;
    background: $color-ui;
    z-index: 9;
    overflow: auto;
    transform: translateX(280px);
    transition: transform 0.2s;
    overflow-y: scroll;

    &.expanded {
        transform: translateX(0);
    }
}


.nav-main {
    margin-top: $page-header-desktop + 20px;
    list-style: none;
    margin-right: 20px;


    .nav-main__link {
        a {
            @include font-size(20px);
            padding-top: $base-spacing-unit;
            padding-bottom: $base-spacing-unit;
        }
        border-bottom: solid 1px #fff;
    }

    .icon {
        float: right;
    }

    a {
        display: block;
        text-transform: uppercase;
    }

    .nav-main__sub {
        a {
            color: $color-vig-mint;
            padding-top: $half-spacing-unit;
            padding-bottom: $half-spacing-unit;
            @include font-size(16px);
            @include attention() {
                color: darken($color-vig-mint, 5%);
            }
        }

        &:first-of-type {
            border-bottom: solid 1px #fff;
        }
    }

}




.nav-cat {
    list-style: none;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    @include font-size(15px);
    @include font-proxima-semi-bold;
    @include mq($from: phone) {
        @include font-size(17px);
    }
    a {
        color: #4A4A4A;
        @include attention() {
            color: #000;
        }
        &.active {
            color: $color-brand;
        }

    }
    li  {
        display: inline-block;
        margin-right: 12px;
        @include mq($from: phone) {
            margin-right: 20px;
        }
    }
}