/* ==========================================================================
   #BOX
   ========================================================================== */

.o-box {
}

.o-box--padding {
    padding: $base-spacing-unit;
}

.o-box--padding, .o-box--darken {
    position: relative;
}

