/* ==========================================================================
   #MEDIA
   ========================================================================== */

$bp-image: 600px;


.media {
    display: block;
    @include mq($from: $bp-image) {
        display: flex;
    }

    align-items: flex-start;

}

.media__image {
    margin-right: $base-spacing-unit*1.5;
    @include mq($until: $bp-image) {
        margin-bottom: $base-spacing-unit;
    }
    @include mq($from: $bp-image) {
        max-width: 220px;
    }
}

.media__body {
    flex: 1;
}


.media--reverse > .media__image {
    order: 1;
    margin: 0 0 0 $base-spacing-unit*1.5;

    @include mq($until: $bp-image) {
        margin-bottom: $base-spacing-unit;
        margin-left: 0;
    }
}
