/* ==========================================================================
   #LOGO
   ========================================================================== */

.site-logo {
    display: block;
    background: url('../img/logo-header.svg') no-repeat;
    text-indent: -9999em;
    width: 160px;
    height: 35px;
    position: relative;
    top: 18px;
    left: 18px;

}


.logo-mmm {
    display: block;
    background: url('../img/logo-mmm.svg') no-repeat;
    text-indent: -9999em;
    min-height: 35vh;
    position: relative;
    top: 60px;
    left: 0;
    @include mq($from: tablet) {
        min-height: 60vh;
        top: 80px;
        left: 0px;
    }

    @include mq($until: tablet, $and: '(orientation: landscape)') {
        min-height: 60vh;
        top: 30px;
        left: 0px;
    }

}