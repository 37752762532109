/* ==========================================================================
   #BREADCRUMBS
   ========================================================================== */


.breadcrumb {
    @include font-size(12px);
    margin: 6px 0;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    li {
        display: inline;
        color: $color-headings;
        a {
            color: $color-grey-light;
            letter-spacing: 1px;
            &:after {
                content: ' >';
            }
        }
    }
}
