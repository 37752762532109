///* ========================================================================
//   #WRAPPER
//   ======================================================================== */

.o-wrapper {
    @include mq($until: tablet) {
        padding-left: $half-spacing-unit;
        padding-right: $half-spacing-unit;
    }
}


.o-wrapper--reading {
    padding-top: 40px;
    background-color: #fff;
    max-width: $page-width - 40px;

    @include mq($from: phone) {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }

    @include mq($from: tablet) {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 20px;
    }
    @include mq($from: desktop) {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 80px;
    }
}

.o-wrapper--large {
    max-width: $page-width + 200px;
}


.o-wrapper--small {
    max-width: 534px;
}



.o-wrapper--nopadding {
    padding-left: 10px;
    padding-right: 10px;
    @include mq($from: 1450px) {
        padding-left: 0px;
        padding-right: 0px;

    }
}
