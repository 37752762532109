/*------------------------------------*\
    #ALIASES
\*------------------------------------*/


$base-spacing-unit: $inuit-global-spacing-unit;
$half-spacing-unit: $base-spacing-unit / 2;


@mixin font-size($args...) {
    @include inuit-font-size($args...);
}
