/* ==========================================================================
   #LAYOUT
   ========================================================================== */

.layout {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;

    @include mq($from: tablet) {
        flex-direction: row;
    }

}


.layout__full {
    width: 100%;
    display: block;
}



.layout__content {
    @include mq($from: tablet) {
        order: 1;
        width: 100%;
        display: block;

    }
}


.layout__sidebar {
    width: 100%;
    order: 1;
    @include mq($from: tablet) {
        width: 280px;
        margin-left: 30px;
        order: 2;
        flex: 0 0 280px;
    }
    @include mq($from: desktop) {
        width: 380px;
        margin-left: 30px;
        order: 2;
        flex: 0 0 380px;
    }

}