/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

h1, .alpha {
    @include inuit-font-size(32px, 32px);

    margin-bottom: $half-spacing-unit;
}


h2, .beta {
    @include font-size(26px, 32px);
    margin-bottom: $half-spacing-unit;
}


h3, .gamma {
    @include inuit-font-size(24px, 28px);
    margin-bottom: $half-spacing-unit;
}

h4, .delta {
    @include inuit-font-size(18px, 22px);
    margin-bottom: $half-spacing-unit;
}

h5, .epsilon {
    @include inuit-font-size(16px, 20px);
    margin-bottom: $half-spacing-unit;
}

h6 {
    @include inuit-font-size(14px, 18px);
    margin-bottom: $half-spacing-unit;
}

