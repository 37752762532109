///* ========================================================================
//   #COLORS
//   ======================================================================== */



$color-brand:       #F19109;

$color-blue:        #2d4485;
$color-blue-grey:   #A1AEC3;
$color-grey:        #4A4A4A;
$color-ui:          #F5F5F5;
$color-ui-light:    #F5F3F2;
$color-headings:    #4A4A4A;

$color-pink:        #B44693;
$color-mint:        #6FB2BC;

$color-vig-mint:            #4299A5;
$color-vig-mint-lighten:    #6FB2BC;
$color-vig-blue:            #1D304F;
$color-vig-blue-lighten:    #215799;
$color-vig-green:           #487A3B;
$color-vig-green-lighten:   #98B740;
$color-vig-orange:          #B56916;
$color-vig-orange-lighten:  #F19109;
$color-vig-purple:          #890E50;
$color-vig-purple-lighten:  #B24992;

$color-grey-light:  #A0A0A0;
$color-btn-primary: #1967BB;


$color-date-default:    $color-grey-light;

$color-block-blue:      $color-brand;