///* ========================================================================
//   #HAMBURGER SETTINGS
//   ======================================================================== */


$hamburgers-padding-x: 0px;
$hamburgers-padding-y: 0px;
$hamburger-types     : (spin);
$hamburger-layer-width                     : 36px ;
$hamburger-layer-height                    : 4px ;
$hamburger-layer-spacing                   : 5px ;
$hamburger-layer-color                     : $color-brand;
$hamburger-layer-border-radius             : 0 ;
$hamburger-hover-opacity                   : 0.7 ;
$hamburger-hover-transition-duration       : 0.15s ;
$hamburger-hover-transition-timing-function: linear ;