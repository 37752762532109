/* ==========================================================================
   #PAGE-HEAD
   ========================================================================== */

.page-head {
    background-color: #fff;
    min-height: $page-header-desktop;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    border-bottom: solid 1px $color-ui;
}
