/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
    display: inline-block; /* [1] */
    vertical-align: middle; /* [2] */
    font: inherit; /* [3] */
    text-align: center; /* [4] */
    margin: 0; /* [4] */
    cursor: pointer; /* [5] */
    padding: 6px 10px 6px 20px;
    transition: $global-transition;
    border-radius: $global-radius;
    border: 0;
    letter-spacing: 1px;
    @include attention() {
        color: darken($color-btn-primary, 10%);
    }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
    text-transform: uppercase;
    background-color: $color-btn-primary;
    color:  #fff;
    text-align: left;
    @include font-size(16px, 30px);
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #fff;
    }

    &:hover,
    &:focus {
        background-color: darken($color-btn-primary, 10%);
    }

}

.c-btn--secondary {
    background-color: #2f4054;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #fff;
    }

    &:hover,
    &:focus {
        background-color: #1d2733;
    }

}

.c-btn--tertiary {
    background-color: #fff;
    color: #4a8ec2;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #4a8ec2;
    }

    &:hover,
    &:focus {
        color: #3774a2;
    }

}

/* Size variants
   ========================================================================== */

.c-btn--small {
    padding: inuit-rem($inuit-global-spacing-unit-tiny) inuit-rem($inuit-global-spacing-unit-small);
}

.c-btn--large {
    padding: inuit-rem($inuit-global-spacing-unit) inuit-rem($inuit-global-spacing-unit-large);
}

.c-btn--padding {
    padding-left: $base-spacing-unit*3;
    padding-right: $base-spacing-unit*3;
    @include mq($until: phone) {
        max-width: 300px;
        width: 100%;
    }
}

.c-btn--full {
    width: 100%;
    max-width: 380px;
}

/* Ghost buttons
   ========================================================================== */

/**
 * Ghost buttons have see-through backgrounds and are bordered.
 */

$btn-ghost-border-width: 2px;

.c-btn--ghost {
    border: $btn-ghost-border-width solid currentColor;
    padding: ($inuit-global-spacing-unit-small - $btn-ghost-border-width) ($inuit-global-spacing-unit - $btn-ghost-border-width);

    &,
    &:hover,
    &:active,
    &:focus {
        background: none;
    }

    &.c-btn--small {
        padding: ($inuit-global-spacing-unit-tiny - $btn-ghost-border-width) ($inuit-global-spacing-unit-small - $btn-ghost-border-width);
    }

    &.c-btn--large {
        padding: ($inuit-global-spacing-unit - $btn-ghost-border-width) ($inuit-global-spacing-unit-large - $btn-ghost-border-width);
    }

    &.c-btn--primary {
        color: #4a8ec2;

        &:hover,
        &:focus {
            color: #3774a2;
        }

    }

    &.c-btn--secondary {
        color: #2f4054;

        &:hover,
        &:focus {
            color: #1d2733;
        }

    }

    &.c-btn--tertiary {
        color: #fff;

        &:hover,
        &:focus {
            color: #fff;
        }

    }

}

/* Naked, no body, just naked
   ========================================================================== */

.c-btn--naked {
    padding: 0 !important;
}


button:focus {
    outline:0;
}
